import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { ActionLinkHolder, ActionsHolder, AmountInfoView, ConfirmAction, ItemList, LoadingPlaceholder, PageWrapper, Panel, RestrictedToCountries, Row, SectionTitle, ShopItemView, Title } from "../Ui"
import Button from "@root/components/Button"

const ViewCart: React.FC = () => {
  const {state, dispatch} = useECommerce()
  const [confirmAbort, setConfirmAbort] = useState(false)

  useEffect(() => {
    dispatch({type: "ecommerce__getCart"})
  }, [])

  if (!state.cart) {
    return <LoadingPlaceholder />
  }

  return <PageWrapper>
    <Row><Title>{I18n.t("ecommerce.cart.title")}</Title></Row>
    <Row>
      <ItemList>
        {_.map(state.cart.entries, (entry) => {
          return <ShopItemView item={entry.item }key={entry.item.sku}>
            <AmountInfoView item={entry.item} amountInfo={entry.amount_info} withChanger={entry.amount_info.can_change && entry.item.configuration_type !== "select_stickers"} />
          </ShopItemView>
        })}
      </ItemList>
    </Row>
    <Row>
      <ActionsHolder>
        <Button onClick={() => dispatch({type: "ecommerce__toBillingAddress", data: { needsShipping: state.cart.needs_shipping }})}>
          {I18n.t("ecommerce.cart.action_continue")}
        </Button>
        <ConfirmAction
          actionLabel={I18n.t("ecommerce.cart.action_abort")}
          action={() => dispatch({type: "navigation__set", data: {navigation: {page: "landing"}}})}
          confirmQuestion={I18n.t("ecommerce.cart.confirm_abort")}
          confirmYes={I18n.t("ecommerce.cart.confirm_abort_yes")}
          confirmNo={I18n.t("ecommerce.cart.confirm_abort_no")} />
        </ActionsHolder>
    </Row>
    {state.cart.restricted_to_countries &&
      <Row>
        <RestrictedToCountries countries={state.cart.restricted_to_countries} />
      </Row>}
  </PageWrapper>
}

export default ViewCart
