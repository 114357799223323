import React, { ChangeEvent, useEffect, useState } from "react"
import _ from "lodash"
import { useECommerce } from "../context"
import I18n from "i18n-js"
import { ActionLinkHolder, ActionsHolder, AmountInfoView, ButtonSubinfo, ConfirmAction, Info, ItemList, ItemListSection, LoadingPlaceholder, PageWrapper, Panel, PriceValue, Row, SectionTitle, ShopItemView, ShortAddress, Title, babelPropsForExternalLinks } from "../Ui"
import Button from "@root/components/Button"

const ViewSummary: React.FC = () => {
  const {state, dispatch} = useECommerce()
  const [couponCode, setCouponCode] = useState<string>("")
  const [termsAccepted, setTermsAccepted] = useState(false)

  useEffect(() => {
    dispatch({type: "ecommerce__getSummary"})
  }, [])

  if (!state.summary) {
    return <LoadingPlaceholder />
  }

  const onChangeCouponInput = (evt: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(evt.target.value)
  }

  const removeCouponCode = () => dispatch({type: "ecommerce__removeCouponCode"})

  const useCouponCode = () => {
    if (couponCode && couponCode.length > 0) {
      dispatch({type: "ecommerce__useCouponCode", data: {code: couponCode}})
    }
  }

  const legalProps = babelPropsForExternalLinks({
    "terms_link": {
      label: I18n.t("ecommerce.summary.payment_legal.terms_label"),
      href: I18n.t("ecommerce.summary.payment_legal.terms_href"),
    },
    "privacy_link": {
      label: I18n.t("ecommerce.summary.payment_legal.privacy_label"),
      href: I18n.t("ecommerce.summary.payment_legal.privacy_href"),
    },
    "mastercard_link": {
      label: I18n.t("ecommerce.summary.payment_legal.master_card_secure_code_label"),
      href: I18n.t("ecommerce.summary.payment_legal.master_card_secure_code_href"),
    },
    "visa_link": {
      label: I18n.t("ecommerce.summary.payment_legal.verified_by_visa_label"),
      href: I18n.t("ecommerce.summary.payment_legal.verified_by_visa_href"),
    },
  })
  
  return <PageWrapper variant={state.paymentButtonDisabled ? "hideDuringPayment" : undefined}>
    <Row>
      <Title>{I18n.t("ecommerce.summary.title")}</Title>
    </Row>
    <Row>
      <Info>{I18n.t("ecommerce.summary.info")}</Info>
    </Row>
    <Row>
      <ItemList>
        {_.map(state.summary.entries, (entry) => {
          return <ShopItemView key={entry.item.sku} item={entry.item}>
            <AmountInfoView item={entry.item} amountInfo={entry.amount_info} />
          </ShopItemView>
        })}
      </ItemList>
    </Row>
    <Row>
      <ItemList>
        <ItemListSection>{I18n.t("ecommerce.summary.cost_segments_title", {item_amount: state.summary.entries.length})}</ItemListSection>
        <div className={`neo__ec__coupon neo__ec__coupon--${state.summary.coupon_code ? "applied" : "notApplied"}`}>
          <div>{I18n.t("ecommerce.summary.coupon_title")}</div>
          {state.summary.coupon_code
            ? <div>
                <span>{state.summary.coupon_code.code}</span>
                <a onClick={removeCouponCode}>{I18n.t("ecommerce.summary.action_coupon_remove")}</a>
              </div>
            : <div>
                <input className="neo__ec__textInput" onChange={onChangeCouponInput} value={couponCode} />
                <a onClick={useCouponCode}>{I18n.t("ecommerce.summary.action_coupon_use")}</a>
              </div>}
        </div>
        {_.map(state.summary.cost_segments, (cs, index) => {
          return <div className="neo__ec__costSegment" key={index}>
            <div>{cs.title}</div>
            <div><PriceValue value={cs.value} /></div>
          </div>})}
      </ItemList>
    </Row>
    <Row>
      <ItemList>
        <ItemListSection>{I18n.t("ecommerce.summary.billing_address_title")}</ItemListSection>
        <ShortAddress address={state.summary.billing_address} />
        {state.summary.shipping_address && <>
            <ItemListSection>{I18n.t("ecommerce.summary.shipping_address_title")}</ItemListSection>
            <ShortAddress address={state.summary.shipping_address} />
          </>}
        {!state.paymentButtonDisabled &&
          <div className="neo__ec__changeAddressesLinkHolder">
            <a onClick={() => dispatch({type: "ecommerce__toBillingAddress", data: {needsShipping: !!state.summary.shipping_address}})}>
              {I18n.t("ecommerce.summary.action_change_addresses")}
            </a>
          </div>}
      </ItemList>
    </Row>
    <Row>
      <Panel style="transparent" size="slim">
        <div className="neo__ec__paymentLegalInfo">
          <p className="neo__ec__paymentLegalInfo__creditCardInfo">
            <span dangerouslySetInnerHTML={{__html: I18n.t("ecommerce.summary.payment_legal.info_credit_card", legalProps)}} />
          </p>
          <p className="neo__ec__paymentLegalInfo__checkHolder">
            <label>
              <span>{!state.paymentButtonDisabled && <input type="checkbox" checked={termsAccepted} onChange={(evt) => setTermsAccepted(evt.target.checked)} />}</span>
              <span dangerouslySetInnerHTML={{__html: I18n.t("ecommerce.summary.payment_legal.info_checkbox", legalProps)}} />
            </label>
          </p>
          <p className="neo__ec__paymentLegalInfo__mandatoryInfo">{I18n.t("ecommerce.summary.payment_legal.mandatory_label")}</p>
          <p className="neo__ec__paymentLegalInfo__infoProceeding">{I18n.t("ecommerce.summary.payment_legal.info_proceeding")}</p>
        </div>
      </Panel>
    </Row>
    <Row>
      <ActionsHolder>
        {_.map(state.summary.payment_options, (pm) => {
          return <Button disabled={state.paymentButtonDisabled || !termsAccepted} key={pm.type} onClick={() => dispatch({type: "payment__start", data: {billingAddress: state.summary.billing_address, shippingAddress: state.summary.shipping_address}})}>{pm.name}</Button>
        })}
        <ButtonSubinfo>{termsAccepted ? I18n.t("ecommerce.summary.action_pay_info") : I18n.t("ecommerce.summary.payment_legal.accept_terms_info")}</ButtonSubinfo>
        {!state.paymentButtonDisabled && <ConfirmAction
          actionLabel={I18n.t("ecommerce.summary.action_abort")}
          action={() => dispatch({type: "navigation__set", data: {navigation: {page: "landing"}}})}
          confirmQuestion={I18n.t("ecommerce.summary.confirm_abort")}
          confirmYes={I18n.t("ecommerce.summary.confirm_abort_yes")}
          confirmNo={I18n.t("ecommerce.summary.confirm_abort_no")} />}
      </ActionsHolder>
    </Row>
  </PageWrapper>
}

export default ViewSummary
