import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import shopAlbumImg from "@images/sandbox__shopAlbum.png"
import shopYearbookImg from "@images/sandbox__shopYearbook.png"
import shopStickersImg from "@images/sandbox__shopStickers.png"
import shopCoins1Img from "@images/sandbox__shopCoins1.png"
import shopCoins2Img from "@images/sandbox__shopCoins2.png"
import shopCoins3Img from "@images/sandbox__shopCoins3.png"
import stickerImg from "@images/sandbox__sticker.jpg"
import { Address, ShopItemImage } from "../types"
import ViewSummary from "./ViewSummary"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const ADDRESS: Address = {
  city: "Green Hills",
  country: {name: "Genesis", uid: "sandbox___"},
  firstname: "Sonic",
  lastname: "Hedgehog",
  id: 99999999,
  postcode: "11111",
  street: ["Sunny Road", "1b"],
  telephone: "123456789"
}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>Plain, no coupon used</Note>
      <MockContextProvider state={{...initState, summary: {
        payment_options: [{name: "Adyen Credit Card", type: "adyen_cc"}],
        billing_address: ADDRESS,
        shipping_address: ADDRESS,
        cost_segments: [
          {title: "Product", value: "99,99€"},
          {title: "Shipping", value: "5,99€"}
        ],
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: true,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },          
        ]}}}
      >
        <ViewSummary />
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Coupon used</Note>
      <MockContextProvider state={{...initState, summary: {
        payment_options: [{name: "Adyen Credit Card", type: "adyen_cc"}],
        billing_address: ADDRESS,
        shipping_address: ADDRESS,
        coupon_code: {
          code: "SUPERPANINI",
          discount: "10€"
        },
        cost_segments: [
          {title: "Product", value: "99,99€"},
          {title: "Shipping", value: "5,99€"}
        ],
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: true,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },          
        ]}}}
      >
        <ViewSummary />
      </MockContextProvider>
    </Block>

    <Block>
      <Note>Checkout disabled (should be hidden)</Note>
      <MockContextProvider state={{...initState, paymentButtonDisabled: true, summary: {
        payment_options: [{name: "Adyen Credit Card", type: "adyen_cc"}],
        billing_address: ADDRESS,
        shipping_address: ADDRESS,
        cost_segments: [
          {title: "Product", value: "99,99€"},
          {title: "Shipping", value: "5,99€"},
          {title: "Coupon", value: "-5,99€"},
        ],
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: true,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
        ]}}}
      >
        <ViewSummary />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/Summary", Sandbox]
