import React, { useEffect } from "react"
import _ from "lodash"
import I18n from "i18n-js"
import { useECommerce } from "../context"
import { Info, ItemList, ShopItemView, PageWrapper, Panel, Row, Title } from "../Ui"

const AvailableItems: React.FC = () => {
  const {state, dispatch} = useECommerce()

  useEffect(() => {
    dispatch({type: "ecommerce__getAvailableItems"})
  }, [])

  return <PageWrapper>
    <Row>
      <Title>{I18n.t("ecommerce.available_items.title")}</Title>
    </Row>
    <Row>
      <Info>{I18n.t("ecommerce.available_items.info")}</Info>
    </Row>
    <Row>
      <ItemList>
        {_.map(state.availableItems, (item) => {
          const continueWithThisItem = () => {
            if (item.needs_configuration) {
              dispatch({type: "ecommerce__goToItemConfiguration", data: {item}})
            } else {
              dispatch({type: "ecommerce__addItemToCartAndShowCart", data: {item}})
            }
          }
          return <ShopItemView key={item.sku}
            item={item}
            actionLabel={!item.out_of_stock && I18n.t("ecommerce.available_items.action_continue_with_item")}
            action={!item.out_of_stock && continueWithThisItem} />
        })}
      </ItemList>
    </Row>
  </PageWrapper>
}

export default AvailableItems
