import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import shopAlbumImg from "@images/sandbox__shopAlbum.png"
import shopYearbookImg from "@images/sandbox__shopYearbook.png"
import shopStickersImg from "@images/sandbox__shopStickers.png"
import shopCoins1Img from "@images/sandbox__shopCoins1.png"
import shopCoins2Img from "@images/sandbox__shopCoins2.png"
import shopCoins3Img from "@images/sandbox__shopCoins3.png"
import stickerImg from "@images/sandbox__sticker.jpg"
import { ShopItemImage } from "../types"
import AvailableItems from "./AvailableItems"

const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Note>Some Items for a collection, Printed album is <strong>out_of_stock</strong>!</Note>
    <Block>
      <MockContextProvider state={{...initState, availableItems: [
        {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
        {name: "Printed album", out_of_stock: true, description: "Album with all stickers already in it", price: {value: "99,99€", subtitle: "per item"}, sku: "SANDBOX_ALBUM", image: itemImg(shopAlbumImg)},
        {name: "XL Stickers", description: "Should dispatch navigation to sticker selection!", price: {value: "1,99€", subtitle: "per item"}, sku: "SANDBOX_STICKER", needs_configuration: true, configuration_type: "select_stickers", image: itemImg(shopStickersImg)},
      ]}}>
        <AvailableItems />
      </MockContextProvider>
    </Block>
    <Note>Coins Shop sample</Note>
    <Block>
      <MockContextProvider state={{...initState, availableItems: [
        {name: "100 COINS", description: "Buy 500 COINS to use in the app to buy virtual packs", price: {value: "1,19€", subtitle: "per item"}, sku: "SANDBOX_COINS_100", image: itemImg(shopCoins1Img)},
        {name: "200 COINS", description: "Buy 200 COINS to use in the app to buy virtual packs", price: {value: "2,29€", subtitle: "per item"}, sku: "SANDBOX_COINS_200", image: itemImg(shopCoins2Img)},
        {name: "500 COINS", description: "Buy 500 COINS to use in the app to buy virtual packs", price: {value: "3,49€", subtitle: "per item"}, sku: "SANDBOX_COINS_500", image: itemImg(shopCoins2Img)},
        {name: "1000 COINS", description: "Buy 1000 COINS to use in the app to buy virtual packs", price: {value: "4,99€", subtitle: "per item"}, sku: "SANDBOX_COINS_1000", image: itemImg(shopCoins3Img)},
      ]}}>
        <AvailableItems />
      </MockContextProvider>
    </Block>
    <Note>None</Note>
    <Block>
      <MockContextProvider state={{...initState}}>
        <AvailableItems />
      </MockContextProvider>
    </Block>
  </>
}

export default ["ec/AvailableItems", Sandbox]
