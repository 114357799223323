import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../pages/sandbox'
import _ from "lodash"
import { initState } from "../state"
import { MockContextProvider } from "../context"
import { Address, ShopItemImage } from "../types"
import ViewOrder, { DedicatedOrderPage } from "./ViewOrder"

import shopYearbookImg from "@images/sandbox__shopYearbook.png"
const itemImg = (src: string): ShopItemImage => {return {large: src, thumb: src}}

const Sandbox:React.FC = () => {
  return <>
    <Block>
      <Note>Order</Note>
      <DedicatedOrderPage order={{
        order_id: 99999999,
        order_number: "#45678900",
        order_status: "Your payment is about to be verified.",
        billing_address: {city: "Bochum", country: {name: "Germany", uid: "GER"}, firstname: "Sonic", lastname: "Hedgehog", postcode: "12345", street: ["Streeeeet", "62"], id: 99999123, telephone: "(+49) 12345678"},
        entries: [
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: false,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
          {
            item: {name: "Super Yearbook 2k", description: "Best of it all", price: {value: "1000,99€", subtitle: "per item"}, sku: "SANDBOX_YEARBOOK", image: itemImg(shopYearbookImg)},
            amount_info: {
              amount: 1,
              can_change: false,
              max: 10,
              total_price: {
                value: "99€",
                subtitle: "total"
              }
            }
          },
        ],
        payment_method: "Card"
      }} />
    </Block>
    <Block>
      <Note>Error</Note>
      <DedicatedOrderPage error={{error: {type: "not-found", description: "Order not found"}}} />
    </Block>
  </>
}

export default ["ec/DedicatedOrder", Sandbox]
